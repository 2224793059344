(function(window, document, $) {
  'use strict';

  function validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }

    return false;
  }

  $(document).ready(function() {
    const formResponseElement = $('.contact__form-response');

    $('.phone').inputmask('(99) 9999-9999[9]');

    function showFormResponseMessage(status, message) {
      formResponseElement.addClass(status);
      formResponseElement.html(message);
      formResponseElement.slideDown();
    }

    function hideFormResponseMessage() {
      formResponseElement.slideUp(function() {
        formResponseElement.empty();
        formResponseElement.removeClass('is-success is-error');
      });
    }

    $('#contact_form').submit(function(event) {
      event.preventDefault();
      const form = $(this);
      const mail = form.find('[name="cf_email"]').val();
      let formResponseMessage;
      let formStatusClass;

      if (!validateEmail(mail)) {
        showFormResponseMessage('is-error', 'E-mail inválido!');
        return false;
      }

      $.ajax({
        method: 'POST',
        data: form.serialize(),
        url: window.location.href,
        beforeSend: function() {
          hideFormResponseMessage();
          form.addClass('is-loading');
        }
      }).done(function(response) {
        const parsedResponse = JSON.parse(response);

        formStatusClass = parsedResponse.status === 'false' ? 'is-error' : 'is-success';
        formResponseMessage = parsedResponse.message;

        if( parsedResponse.status !== 'false' && typeof gtag === 'function'){
          gtag('event', 'Envio de Formulário', {
            'event_category' : 'Envio de Formulário',
          });
          console.log('Analytics - Envio de Formulário');
        }

      })
      .fail(function(error) {
        console.error(error);

        formStatusClass = 'is-error';
        formResponseMessage = 'Erro ao enviar o contato. Tente novamente mais tarde.'
      })
      .always(function() {
        setTimeout(function() {
          showFormResponseMessage(formStatusClass, formResponseMessage);
          form.removeClass('is-loading');

          if (formStatusClass === 'is-success') {
            form[0].reset();
          }
        }, 1000);
      })

    });
  });

  $('.cta a.button-primary').click(function(){
    if( typeof gtag === 'function' ){
      gtag('event', 'Acesse Agora', {
        'event_category' : 'Acesse Agora',
      });
      console.log('Analytics - Acesse Agora');
    } else {
      console.log('Analytics - Erro!');
    }

    window.open( $(this).attr('href'), '_blank' );

    return false;
  });

  $('.links a').click(function(){
    if( typeof gtag === 'function' ){
      gtag('event', 'Social', {
        'event_category' : $(this).data('type'),
      });
      console.log('Analytics - Social ' + $(this).data('type'));
    } else {
      console.log('Analytics - Erro!');
    }

    window.open( $(this).attr('href'), '_blank' );

    return false;
  });

})(window, document, jQuery);
